//
// Classes para alinhar elementos utilizando o flex que podem ser utilizadas de forma
// direta em cada elemento html.
//

.flex-center {
  @extend .display-flex !optional;

  justify-content: center;
  align-items: center;
}

.flex-space-between {
  @extend .display-flex !optional;

  display: flex;
  justify-content: space-between;
}

.flex-end {
  @extend .display-flex !optional;

  justify-content: flex-end;
}

.flex-align-items-center {
  @extend .display-flex !optional;

  align-items: center;
}

// @deprecated renomeado para .flex-center
.align-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
