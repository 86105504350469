ag-grid-angular {
  width: 100%;
  height: 400px;
  font-family: Roboto, 'Helvetica Neue', sans-serif !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);

  .ag-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .ag-cell {
      font-size: 14px !important;
    }
  }

  .ag-row-selected {
    border-bottom: 3px solid #00bea5 !important;
  }

  .ag-header {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;

    .ag-header-cell {
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  }

  .ag-pinned-left-cols-container {
    .ag-row {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .sortable {
    cursor: pointer !important;
  }

  .ag-cell-focus,
  .ag-cell-no-focus {
    border: none !important;
  }

  .ag-cell:focus {
    border: none !important;
    outline: none;
  }

  .ag-body-container,
  .ag-header-row {
    width: 100% !important;
  }

  .ag-cell,
  .ag-header-cell,
  .ag-header-group-cell {
    position: initial !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .ag-layout-normal .ag-row {
    width: auto;
  }

  .ag-theme-material .ag-ltr .ag-cell-no-focus {
    outline: none;
  }

  .ag-header-viewport .ag-header-container {
    width: 100% !important;

    .ag-header-row {
      padding-left: 14px !important;
      padding-right: 14px !important;
    }
  }

  .ag-center-cols-clipper .ag-row {
    padding-left: 14px !important;
    padding-right: 4px !important;
  }

  &.ag-grid-fixed {
    .ag-pinned-left-header .ag-header-row {
      padding-left: 14px !important;
    }

    .ag-header-viewport .ag-header-row {
      padding-left: 6px !important;
    }

    .ag-pinned-left-cols-container .ag-row {
      padding-left: 14px !important;
    }

    .ag-body-viewport-wrapper .ag-row {
      padding-left: 6px !important;
    }
  }
}

.no-border-bottom {
  border-bottom: 0;
}

// Importante: esse elemento está criando uma parte branca que
// sobrescreve o header do ag-grid quando não há elementos no grid
.ag-overlay-no-rows-center {
  display: none;
}
.ag-theme-material {
  --ag-row-hover-color: #eeeeee !important;
  --ag-selected-row-background-color: #f5f5f5 !important;
  --ag-foreground-color: #666 !important;
  --ag-data-color: #666 !important;

  .ag-row-drag {
    background-color: transparent;
    background-image: url(/assets/images/image-arrastar.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    height: 16px;
    opacity: 0.87;
    width: 16px;
    background-position-x: left;
    background-position-y: 4px;
    float: left;
    height: 100%;
    width: 28px;
    cursor: -webkit-grab;
  }
  .ag-row-dragging {
    cursor: -webkit-grabbing !important;
    background-color: #e5e5e5 !important;
  }
  .ag-row-hover:not(.ag-full-width-row)::before,
  .ag-row-selected::before,
  .ag-row-hover.ag-full-width-row.ag-row-group::before {
    z-index: -1 !important;
  }
}
.ag-dnd-ghost {
  cursor: -webkit-grabbing !important;
}
